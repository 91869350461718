import * as React from 'react'
import { Link, Script } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import './src/styles/default.css'
import '@fontsource/oswald/latin.css'
import '@fontsource/roboto/latin.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => {
      const childProps = { ...props }
      delete childProps.activeClassName
      childProps.target = props.target || '_blank'
      childProps.rel = props.rel || 'noopener noreferrer'
      if (props.href.includes(process.env.GATSBY_COMPANY_URL)) {
        return (
          <a data-location='internal' {...childProps}>
            {props.children}
          </a>
        )
      }
      return (
        <a data-location='external' {...childProps}>
          {props.children}
        </a>
      )
    }}
    internalLinkComponent={({ href, ...props }) => {
      if (href === 'index' || href === '/') {
        return (
          <a data-location='auction' href={href} {...props}>
            {props.children}
          </a>
        )
      }
      return <Link to={href} {...props} />
    }}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <link href={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/css/bidjs--full.min.css`} rel='stylesheet' type='text/css' />
      <Script id='bid-js-script' src={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/js/bidjs.min.js`} />
    </>
  )
}
